@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.select {
  position: relative;
  border: 1px solid $grey;
  border-radius: 14px;
  background: $white;
  min-width: 150px;
  cursor: pointer;

  &.opened {
    .selectListWrapper {
      display: block;
    }

    .selectIcon {
      transform: rotate(180deg);
    }
  }

  &Value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 8px 13px 16px;
  }

  &Icon {
    @include transition(.3, ease);
    margin-left: 16px;
  }

  &List {
    max-height: 160px;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $light_grey;
      outline: 1px solid $white;
      border-radius: 10px;
    }

    &Wrapper {
      display: none;
      position: absolute;
      top: 54px;
      left: 0;
      right: 0;
      background: $white;
      border-radius: 14px;
      padding: 16px 8px 16px 0;
    }

    &Item {
      padding: 8px 16px;

      &:hover {
        background: $light_grey;
      }
    }
  }
}

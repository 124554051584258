@import '../../../shared/scss/variables.scss';
@import '../../../shared/scss/mixins.scss';

#progressToNetChart {
  @include respond-to('small') {
    width: calc(100% + 70px);
  }
  @include respond-to('large') {
    width: calc(100% + 80px);
  }
  margin-left: -42px;
  margin-top: -25px;
  height: 122px;
  width: calc(100% + 60px);

  @media (max-width: 800px) {
    width: 100%;
    margin-left: 0;
    height: 93px;
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    margin-left: -42px;
    height: 120px;
    width: calc(100% + 65px);
  }
  @media (max-width: 502px) {
    width: 100%;
    margin-left: 0;
    height: 90px;
  }
  @media (max-width: 446px) {
    height: 113px;
  }
  @media (max-width: 334px) {
    height: 136px;
  }
}

@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.wrapper {
  @include respond-to('medium') {
    height: 285px;
  }
  max-height: 400px;
  overflow-y: auto;
  padding-right: 10px;
  width: 100%;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $light_grey;
    outline: 1px solid $white;
    border-radius: 10px;
  }

  &.empty {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $grey;
  }
}

.row {
  @include respond-to('small') {
    font: 400 14px/20px 'Inter';
  }
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  font: 400 12px/20px 'Inter';
  color: $dark_blue;

  &:last-child {
    margin-bottom: 0;
  }
}

.icon {
  margin-right: 12px;
}

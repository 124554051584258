@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.header {
  color: $dark_blue;
  font: 700 20px/30px 'Inter';
  display: flex;
  align-items: center;
}

.subHeader {
  @include respond-to('small') {
    font: 400 14px/20px 'Inter';
  }
  color: $grey;
  font: 400 12px/20px 'Inter';
  margin-left: 8px
}
@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.text {
   @include respond-to('small') {
      font: 400 14px/20px 'Inter';
   }
   font: 400 12px/20px 'Inter';
   color: $grey;
   hyphens: auto;
}

.locationContainer {
   display: flex;
   align-items: center;

   .text {
      margin-left: 10px;
   }
}

.weatherContainer {
   margin-top: 20px;
   display: flex;
   align-items: center;
   justify-content: center;
}

.weatherTextContainer {
   margin-left: 18px;
}

.weatherDegrees {
   @include respond-to('small') {
      font: 800 24px/34px 'Inter';
   }
   font: 800 20px/30px 'Inter';
   color: $dark_blue;
   margin: 0 0 8px;
}
@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.sidebar {
  @include respond-to('small') {
    top: 0;
    flex-direction: column;
    right: auto;
    padding: 50px 16px 270px;
    border-radius: 0 40px 40px 0;
  }
  @media (max-height: 1000px) and (min-width: 768px) {
    padding-bottom: 150px;
  }
  @media (max-height: 800px) and (min-width: 768px) {
    padding-bottom: 30px;
  }
  display: flex;
  background: $dark_blue;
  border-radius: 40px 40px 0 0;
  padding: 16px 24px 32px;
  position: fixed;
  left: 0;
  right: 0;
  top: auto;
  bottom: 0;
  z-index: 999;

  &Closed {
    .drawer {
      @include respond-to('small') {
        width: 60px;
      }
    }

    .logo {
      @include respond-to('small') {
        display: flex;
        justify-content: center;
        margin: 0 auto 60px;
      }
    }

    .toggle {
      margin: auto auto 0;
    }

    .navLink {
      padding: 15px;
    }
  }

  &:not(.sidebarClosed) {
    .navText {
      @include respond-to('small') {
        margin-left: 16px;
      }
    }
  }
}

.drawer {
  @include respond-to('small') {
    width: 228px;
    overflow-x: hidden;
  }
  @include respond-to('xs') {
    overflow: hidden;
  }
}

.logo {
  @include respond-to('small') {
    display: flex;
    align-items: center;
    margin: 0 0 60px 20px;
  }
  display: none;

  &Name {
    font: 700 24px/34px 'Inter';
    color: $white;
    margin-left: 10px;
  }
}

.list {
  @include respond-to('small') {
    display: block;
  }
  @include respond-to('xs') {
    padding: 0 !important;
  }
  display: flex;
  justify-content: space-between;
}

.nav {
  &Item {
    @include respond-to('small') {
      margin-bottom: 16px;
    }
    @include respond-to('xs') {
      margin: 8px 16px 0 0;

      &:last-child {
        margin-right: 0;
      }
    }

    &:hover {
      @include respond-to('small') {
        background-color: transparent;
      }
    }
  }

  &Link {
    @include respond-to('small') {
      border-radius: 20px;
      padding: 15px 24px;
    }
    @include transition(0.3, ease);
    text-decoration: none;
    width: 100%;

    &Active,
    &:not(.disabled):hover {
      @include respond-to('small') {
        background: $lighter_blue;
      }
      @include respond-to('xs') {
        .navIcon {
          &::before {
            @include transition(0.3, ease);
            content: '';
            position: absolute;
            width: 36px;
            height: 36px;
            background: $lighter_blue;
            z-index: -1;
            border-radius: 10px;
          }
        }
      }
    }

    &.disabled {
      @include respond-to('small') {
        background: rgba($light_grey, 0.7);
      }
      @include respond-to('xs') {
        .navIcon {
          &::before {
            @include transition(0.3, ease);
            content: '';
            position: absolute;
            width: 36px;
            height: 36px;
            background: rgba($light_grey, 0.7);
            z-index: -1;
            border-radius: 10px;
          }
        }
      }

      .navText {
        @include respond-to('small') {
          color: $white;
        }
      }

      .navIcon {
        background: $light_grey;

        svg {
          path {
            fill: $dark_blue;
          }
        }
      }
    }
  }

  &Btn {
    @include respond-to('xs') {
      flex-direction: column;
    }
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &Icon {
    @include respond-to('small') {
      width: 30px;
      height: 30px;
    }
    @include respond-to('xs') {
      margin-bottom: 10px;
    }
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: rgba(255, 255, 255, 0.2);

    &::before {
      @include respond-to('xs') {
        @include transition(0.3, ease);
        content: '';
      }
    }

    path {
      fill: $white;
    }
  }

  &Text {
    color: $white;

    span {
      @include respond-to('small') {
        font: 600 14px/20px 'Inter';
      }
      font: 600 9px/18px 'Inter';
    }
  }
}

.toggle {
  @include respond-to('small') {
    display: flex !important;
  }
  display: none !important;
  width: 50px;
  height: 50px;

  &:hover {
    background: darken(#001b40, 10%);
  }
}

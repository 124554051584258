@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.group {
  margin-bottom: 40px;

  &Name {
    font: 700 24px/34px 'Inter';
    color: $dark_blue;
    margin-right: 16px;
  }
}

.logo {
  margin-right: 24px;
}

.user {
  display: flex;
  align-items: center;
  cursor: pointer;

  &Img {
    width: 44px;
    height: 44px;
  }

  &Name {
    @include respond-to('xs') {
      display: none;
    }
    color: $dark_blue;
    font: 700 14px/20px 'Inter';
    margin-left: 18px;
    text-transform: capitalize;
  }
}

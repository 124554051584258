@import '../../shared/scss/variables.scss';

.battery {
  position: relative;

  @supports not (aspect-ratio: 10 / 4) {
    width: 100%;
    height: 100%;
  }

  &Value {
    color: $dark_blue;
    font: 700 20px/30px 'Inter';
    position: absolute;
    top: calc(50% - 4px);
    left: calc(50% - 8px);
    transform: translate(-50%, -50%);
  }
}

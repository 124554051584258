@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.wrapper {
  @include respond-to('xs') {
    flex-direction: column;
    width: 100%;
  }
  display: flex;
  align-items: flex-start;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  &Wrapper {
    @include respond-to('small') {
      margin: 0 24px 0 0;
    }
    margin-bottom: 20px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
  }
}

.description {
  @include respond-to('xs') {
    width: 100%;
  }

  &Row {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    &Header {
      @include respond-to('small') {
        font: 400 14px/20px 'Inter';
      }
      font: 400 12px/20px 'Inter';
      color: $grey;
      margin: 0 8px 0 0;
    }

    &Value {
      @include respond-to('small') {
        font: 400 14px/20px 'Inter';
      }
      font: 400 12px/20px 'Inter';
      color: $dark_blue;
      word-break: break-word;
    }

    &Name {
      text-transform: capitalize;
    }
  }
}

.changePass {
  @include respond-to('xs') {
    width: 100%;
    max-width: 400px;
  }
  @include transition(0.3, ease-out);
  border: 1.5px solid $dark_blue;
  border-radius: 14px;
  padding: 15px 16px;
  font: 600 14px/20px 'Inter';
  color: $dark_blue;
  background: transparent;
  cursor: pointer;
  display: inline-block;
  margin-top: 8px;

  &:hover,
  &:focus,
  &:active {
    background: $black;
    color: $white;
  }
}

.form {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  width: 342px;
  max-width: 100%;
  text-align: left;

  &Field {
    margin-bottom: 16px;
  }
}

.link {
  text-decoration: underline; 
}

#energyUsageChart {
   height: 570px;

   @media (max-width: 858px) {
      height: 300px;
   }
   @media (max-width: 767px) {
      height: 350px;
   }
   @media (max-width: 550px) {
      height: 300px;
   }
   @media (max-width: 502px) {
      height: 299px;
   }
}
@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.container {
  @include respond-to('medium') {
    flex-direction: column;
  }
  @include respond-to('large') {
    flex-direction: row;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.values {
  @include respond-to('large') {
    flex: 0 0 140px;
    margin:0 0 0 5px;
  }
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  color: $grey;
  font: 400 14px/20px 'Inter';
  text-align: center;
  margin-bottom: 8px;
}

@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.header {
  font: 700 20px/30px 'Inter';
  color: $dark_blue;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 24px;
}

.contactBtn {
  @include respond-to('xs') {
    font: 600 12px/20px 'Inter';
    width: 100%;
    max-width: 400px;
  }
  @include transition(0.3, ease-out);
  border: 1.5px solid $dark_blue;
  border-radius: 14px;
  padding: 14px 16px;
  font: 600 14px/20px 'Inter';
  color: $dark_blue;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  width: 145px;
  text-align: center;
  margin-left: 10px;

  &:hover,
  &:focus,
  &:active {
    background: $black;
    color: $white;
  }

  &.desktop {
    @include respond-to('xs') {
      display: none;
    }
  }

  &.mobile {
    @include respond-to('xs') {
      display: block;
    }
    display: none;
    margin: 24px auto 0;
  }
}

.action {
  display: flex;
  align-items: center;
  color: $dark_blue;

  &Img {
    width: 80px;
    margin-right: 16px;
  }

  &List {
    padding: 0;
    list-style-position: inside;

    li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.row {
  @include respond-to('small') {
    font: 400 14px/20px 'Inter';
  }
  font: 400 12px/20px 'Inter';
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 21px;

  &:last-child {
    margin-bottom: 0;
  }

  &Header {
    margin-top: 5px;
    color: $grey;
  }

  &SubHeader {
    color: $dark_blue;
  }

  &Value {
    font: 600 20px/30px 'Inter';
    color: $dark_blue;
  }
}

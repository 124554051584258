@mixin respond-to($name) {
  @if map-has-key($breakpoints, $name) {
    @media #{inspect(map-get($breakpoints, $name))} {
      @content;
    }
  }
}

@mixin transition($time, $type) {
  transition: #{$time}s $type;
}

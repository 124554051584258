//main colors
$white: #ffffff;
$dark_blue: #001b40;
$black: #000F22;
$lighter_blue: #2d4a69;
$grey: #62778e;
$light_grey: #e4eaf0;
$lightest_grey: #f3f7f9;

//charts and statuses
$blue: #57b0f4;
$light_blue: #d9f0ff;
$red: #ea5449;
$light_red: #ffdcda;
$dark_green: #427169;
$light_green: #d6e3e1;
$orange: #F18C2E;
$light_orange: #FCDFB7;

// Media queries
$breakpoints: (
  'xs': (
    max-width: 767px,
  ),
  'small':
  (
    min-width: 768px,
  ),
  'medium': (
    min-width: 1024px,
  ),
  'large': (
    min-width: 1200px,
  ),
  'extra-large': (
    min-width: 2000px,
  ),
);

@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  &Header {
    @include respond-to('small') {
      font: 400 14px/20px 'Inter';
    }
    color: $grey;
    font: 400 12px/20px 'Inter';
    text-transform: capitalize;
  }

  &Value {
    @include respond-to('small') {
      font: 600 14px/20px 'Inter';
    }
    color: $dark_blue;
    font: 600 12px/20px 'Inter';
  }
}

@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.wrapper {
  @include respond-to('medium') {
    flex-direction: column;
  }
  @include respond-to('large') {
    flex-direction: row;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.firstColumn {
  @include respond-to('small') {
    margin: 0 32px 0 0;
    max-width: 130px;
  }
  @include respond-to('medium') {
    margin: 0 0 20px;
    max-width: none;
  }
  @include respond-to('large') {
    max-width: 130px;
    margin: 0 32px 0 0;
  }
  margin: 0 25px 0 0;
  max-width: 120px;
}

.img {
  @include respond-to('small') {
    width: 41px;
    height: 41px;
  }
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.row {
  @include respond-to('small') {
    font: 400 14px/20px 'Inter';
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  font: 400 12px/20px 'Inter';
  color: $dark_blue;

  &:last-child {
    margin-bottom: 0;
  }
}

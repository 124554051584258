@import '../../shared/scss/variables';
@import '../../shared/scss/mixins';

.btn {
  @include respond-to('small') {
    display: none;
  }
  @media screen and (orientation: landscape) {
    display: none;
  }
  @include transition(0.3, ease);
  margin: 24px 0;
  width: 100%;
  border: 1.5px solid $dark_blue;
  border-radius: 14px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 600 12px/20px 'Inter';
  color: $dark_blue;
  background: transparent;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background: $black;
    color: $white;
  }
}

@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

$tableCountWidth: 52px;
$tableXlsWidth: 56px;

.header {
  font: 700 20px/30px 'Inter';
  color: $dark_blue;
  margin: 0;

  &Wrapper {
    @include respond-to('xs') {
      flex-direction: column;
      align-items: flex-start;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;

    .select {
      @include respond-to('xs') {
        margin-top: 32px;
        width: 100%;
      }
    }
  }
}

.subHeader {
  @include respond-to('small') {
    font: 400 14px/20px 'Inter';
  }
  font: 400 12px/20px 'Inter';
  color: $grey;
  margin: 8px 0 0;
}

.table {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 2px;

  &Header {
    @include respond-to('small') {
      font: 700 14px/20px 'Inter';
    }
    display: flex;
    flex-wrap: nowrap;
    gap: 2px;
    color: $grey;
    font: 700 12px/20px 'Inter';

    .tableCell {
      background: $light_grey;

      &:first-child {
        border-top-left-radius: 20px;
      }

      &:last-child {
        border-top-right-radius: 20px;
      }
    }
  }

  &Body {
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: $dark_blue;

    .tableCell {
      display: flex;
      align-items: center;

      &.count {
        justify-content: end;
      }
    }
  }

  &Row {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    flex: 0 0 100%;
    gap: 2px;

    &:last-child {
      .tableCell {
        &:first-child {
          border-bottom-left-radius: 20px;
        }

        &:last-child {
          border-bottom-right-radius: 20px;
        }
      }
    }
  }

  &Cell {
    padding: 15px 16px;
    background: $white;

    &.count {
      flex: 0 0 $tableCountWidth;
    }

    &.content {
      // flex: 0 0 calc(100% - $tableCountWidth - $tableXlsWidth);
      flex-grow: 1;
      display: flex;
      align-items: center;
    }

    &.xls {
      flex: 0 0 $tableXlsWidth;

      a {
        display: flex;
      }
    }
  }
}

.pdfIcon {
  margin-right: 10px;

  img {
    display: block;
  }
}

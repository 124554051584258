@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.mainWrapper {
  text-align: center;
}

.header {
  @include respond-to('small') {
    font: 400 14px/20px 'Inter';
  }
  color: $grey;
  font: 400 12px/20px 'Inter';
  text-align: center;
  margin-bottom: 8px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center
}

.value {
  @include respond-to('small') {
    font: 800 24px/34px 'Inter';
  }
  color: $dark_blue;
  font: 800 20px/30px 'Inter';
  margin-right: 8px;
}

@import '../../shared/scss/variables.scss';

.chip {
  padding: 2px 10px;
  border-radius: 20px;
  font: 400 10px/20px 'Inter';
  text-transform: uppercase;
  white-space: nowrap;
  display: inline-block;

  &.positiveValue {
    color: $dark_green;
    background: $light_green;
  }

  &.negativeValue {
    color: $red;
    background: $light_red;
  }

  &.positiveStatus {
    color: $blue;
    background: $light_blue;
    font-weight: 600;
    letter-spacing: 0.04em;
  }

  &.negativeStatus {
    color: $grey;
    background: $lightest_grey;
    font-weight: 600;
    letter-spacing: 0.04em;
  }

  &.errorStatus {
    color: $red;
    background: $light_red;
    font-weight: 600;
    letter-spacing: 0.04em;
  }

  &.onlineStatus {
    color: $dark_green;
    background: $light_green;
    font-weight: 600;
    letter-spacing: 0.04em;
  }

  &.idleStatus {
    color: $orange;
    background: $light_orange;
    font-weight: 600;
    letter-spacing: 0.04em;
  }
}

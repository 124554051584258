@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700;800&display=swap');
@import './shared/scss/variables';
@import './shared/scss/mixins';

body {
  @include respond-to('small') {
    font: 14px/20px 'Inter';
  }
  margin: 0;
  font-family: -apple-system, 'Inter', BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: 12px/20px 'Inter';
  color: $dark_blue;
  letter-spacing: -0.02em;
  background: $lightest_grey;

  &.hidden {
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.popup-content-container {
  border-radius: 14px !important;
  box-shadow: 0px 0px 8px rgb(0, 0, 0, 0.4) !important;
  overflow: hidden !important;
}

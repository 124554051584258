@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.wrapper {
  @include respond-to('small') {
    font: 400 14px/20px 'Inter';
  }
  display: flex;
  justify-content: space-between;
  width: 100%;
  font: 400 12px/20px 'Inter';
  color: $dark_blue;

  &Header {
    display: flex;
    align-items: center;
  }
}

.pdfIcon {
  margin-right: 8px;
}
@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.fixed {
  position: fixed;
  width: 100vh;
  height: 100vw;
  overflow: hidden;
  z-index: 9999;
  border-radius: 0;
  transform: rotate(90deg);
  transform-origin: right top;
  top: 100%;
  right: 0;
  bottom: 0;
  padding: 54px 16px 24px;

  @media screen and (orientation: landscape) {
    transform: none;
    width: 100vw;
    height: 100vh;
    transform-origin: left top;
    top: 0;
  }

  .wrapper {
    overflow: auto;
  }
}

.header {
  @include respond-to('xs') {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  &.landscape {
    flex-direction: row;
  }
}

.tabs {
  @include respond-to('xs') {
    align-self: center;
  }
}

.back {
  color: $grey;
  font: 600 14px/20px 'Inter';
  background: transparent;
  border: 0;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0;
  display: none;

  &.visible {
    display: flex;
    align-items: center;
  }

  &Icon {
    margin-right: 10px;
  }
}

.exportButton{
  display: inline-flex!important;
  cursor: pointer!important;
  background: none;
}

@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.header {
  font: 700 20px/30px 'Inter';
  color: $dark_blue;
  margin: 0;

  &Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 24px;
  }

  &Icon {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    transition: 0.3s ease;

    &:hover {
      background: $lighter_blue;

      svg {
        path {
          fill: $white;
        }
      }
    }

    &Report {
      svg {
        margin: 8px 0 0 6px;
      }
    }

    svg {
      width: auto;
      height: auto;

      path {
        transition: 0.3s ease;
      }
    }
  }
}

.subHeader {
  font: 400 14px/20px 'Inter';
  color: $grey;
  margin-left: 8px;
}

.innerTitle {
  font: 700 16px/26px 'Inter';
  color: $dark_blue;
  margin: 0;
  margin-bottom: 24px;
}

.card {
  background: $white;
  border-radius: 40px;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;

  &.start {
    justify-content: flex-start;
  }

  &.startColumn {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    .cardsWrapper {
      flex-direction: column;
      width: 100%;
    }
  }

  &.block {
    display: block;
  }

  &Wrapper {
    @include respond-to('small') {
      height: 100%;
    }
    display: flex;
    flex-direction: column;

    &.paddingInsteadTitle {
      @include respond-to('large') {
        padding-top: 54px;
      }
    }
  }

  &sWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &.center {
      justify-content: center;
    }
  }

  &Operations {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  &.battery {
    .imgWrapper {
      svg {
        max-width: 205px;
      }
    }
  }

  &.solar {
    .imgWrapper {
      svg {
        max-width: 165px;
      }
    }
  }

  &.flex {
    .imgWrapper {
      svg {
        max-width: 175px;
      }
    }
  }

  &.gridPurchases {
    background-image: url('../../assets/img/grid-purchases-bg.svg');
  }
}

.imgWrapper {
  aspect-ratio: 10 / 4;
  min-height: 110px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  @supports not (aspect-ratio: 10 / 4) {
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &::before {
      float: left;
      padding-top: calc(4 / 10) * 100%;
      content: '';
    }

    &::after {
      display: block;
      content: '';
      clear: both;
    }
  }

  svg {
    max-width: 100%;
    max-height: 100%;
  }
}

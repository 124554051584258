@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

$mapMarginTop: 194px;
$mapMarginBottom: 64px;

.header {
  font: 700 20px/30px 'Inter';
  margin: 0 0 24px;
}

.map {
  @include respond-to('small') {
    border-radius: 40px;
    overflow: hidden;
    margin: 0;
  }
  height: calc(100vh - $mapMarginTop - $mapMarginBottom);
  margin: 0 -16px;
  min-height: 320px;

  &Skeleton {
    @include respond-to('small') {
      margin: 0;
    }
    height: calc(100vh - $mapMarginTop - $mapMarginBottom) !important;
    transform: none !important;
    margin: 0 -16px;
  }
}

.popup {
  padding: 16px;
  background: $white;
  color: $dark_blue;
  font: 400 14px/20px 'Inter';

  &Loading {
    display: block;
    padding-top:20px;

    &Spinner {
      position: relative;
    }
  }

  &Group {
    &Name {
      font: 700 16px/26px 'Inter';
      margin: 0 0 8px;
    }

    &Address {
      margin: 0 0 16px;
      text-transform: capitalize;
      max-width: 210px;
      white-space: normal;
      hyphens: auto;
    }

    &Info {
      @include respond-to('small') {
        margin: 0 0 16px;
      }
      margin: 0 16px 0 0;

      &:last-child {
        margin: 0;
      }

      &Wrapper {
        @include respond-to('small') {
          flex-direction: column;
        }
        display: flex;
      }

      &Name {
        color: $grey;
        margin-bottom: 8px;
      }

      &Value {
        margin-bottom: 8px;
        font-weight: 600;
      }
    }
  }
}

@import '../shared/scss/variables';
@import '../shared/scss/mixins';

.dashboard {
  display: flex;
  min-height: 100vh;
  background: $lightest_grey;

  &Container {
    @include respond-to('small') {
      padding: 56px 40px 70px 132px;
    }
    @include transition(.4, ease);
    width: 100%;
    padding: 40px 16px 180px;

    &.open {
      @include respond-to('small') {
        padding: 56px 40px 70px 300px;
      }
    }
  }
}

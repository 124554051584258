@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.mainWrapper {
  @include respond-to('large') {
    min-height: auto
  }
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 241px;
}

.wrapper {
  display: flex;
  font-weight: 400;
  color: $dark_blue;
  margin-top: 24px;
}

.header {
  font: 600 20px/30px 'Inter';
}

.column {
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }
}

@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.wrapper {
  min-width: 100vw;
  min-height: 100vh;
  background: $dark_blue;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $white;
}

.logo {
  @include respond-to('small') {
    margin-bottom: 64px;
  }
  margin-bottom: 48px;
}

.header {
  @include respond-to('small') {
    font: 700 40px/54px 'Inter';
    margin: 0 0 40px;
  }
  font: 700 24px/34px 'Inter';
  margin: 0 0 32px;
}

.button {
  @include transition(.3, ease);
  background: $white;
  color: $dark_blue;
  font: 600 14px/20px 'Inter';
  border: 0;
  border-radius: 14px;
  padding: 13px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover,
  &:active {
    background: $lightest_grey;
  }
}

.icon {
  margin-right: 8px;
}

@import '../../../shared/scss/variables.scss';
@import '../../../shared/scss/mixins.scss';

.performanceChart {
   &__container {
      @include respond-to('small') {
         height: 265px;
      }
      position: relative;
      width: 100%;
      height: 210px;
      z-index: 1
   }
   &__chart {
      height: 265px;
      width: 100%;
      z-index: 1;

      @media (max-width: 800px) {
         height: 264px;
       }
       @media (max-width: 767px) {
         height: 211px;
       }
       @media (max-width: 502px) {
         height: 210px;
       }
   }
   &__text-container {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin-top: -17px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h2, h6 {
         margin: 0;
      }

      h2 {
         @include respond-to('small') {
            font: 800 24px/34px 'Inter';
         }
         text-align: center;
         font: 800 20px/30px 'Inter';
         color: $dark_blue;
      }

      h6 {
         @include respond-to('small') {
            font: 400 14px/30px 'Inter';
         }
         text-align: center;
         font: 400 12px/20px 'Inter';
         text-align: center;
         color: $grey;
      }
   }
}
@import '../../shared/scss/variables.scss';
@import '../../shared/scss/mixins.scss';

.logout {
  text-align: center;

  &Wrapper {
    @include respond-to('small') {
      padding: 32px 24px;
    }
    background: $white;
    padding: 40px 24px 45px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &Header {
    color: $dark_blue;
    font: 700 20px/30px 'Inter';
    margin: 0;
  }

  &Descr {
    font: 400 14px/20px 'Inter';
    margin-top: 32px;
  }

  &Btn {
    @include respond-to('xs') {
      width: 100%;
      max-width: 400px;
    }
    flex: calc(50% - 4px);
    padding: 15px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: 600 14px/20px 'Inter';
    background: transparent;
    border: 1.5px transparent solid;
    cursor: pointer;
    transition: 0.3s ease;

    &Cancel {
      border-color: $dark_blue;
      color: $dark_blue;

      &:hover,
      &:focus,
      &:active {
        background: $black;
        color: $white;
      }
    }

    &Confirm {
      @include respond-to('small') {
        margin: 0 0 0 8px;
      }
      border-color: $dark_blue;
      background: $dark_blue;
      color: $white;
      margin-bottom: 8px;

      &:hover {
        background: #000f22;
      }
    }

    &s {
      @include respond-to('small') {
        flex-direction: row;
      }
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;
      width: 100%;
    }
  }
}

.close {
  position: absolute;
  top: 23px;
  right: 23px;
  cursor: pointer;
  color: #62778e;
  cursor: pointer;
}
